// App.js
import React, {useEffect, useState} from "react";
import ParticipantList from "./components/ParticipantList";
import './App.css'

const App = () => {
    const [chatId, setChatId] = useState(null);

    useEffect(() => {
        // Telegram Web App'ning chat ID sini olish
        const tg = window.Telegram.WebApp;
        const userChatId = tg.initDataUnsafe?.user?.id;
        setChatId(userChatId);
    }, []);

    // if (!chatId) {
    //     return (<div className="telegram-warning">
    //         <div className="warning-icon">
    //             <i className="fas fa-exclamation-triangle"></i>
    //         </div>
    //         <div className="warning-message">
    //             Telegram Web App o'rnatilgan bo'lishi kerak.
    //         </div>
    //     </div>);
    // }

    return (
        <div>
            <h1>Ovoz berish tizimi</h1>
            <ParticipantList chatId={chatId}/>
        </div>
    );
};

export default App;
