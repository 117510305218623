// api.js
const apiUrl = "http://95.130.227.234:8080/vote/candidate/list";
const voteUrl = "http://95.130.227.234:8080/vote";

// Ishtirokchilarni olish
export const fetchParticipants = async () => {
    try {
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error("Ma'lumot olishda xatolik!");
        const participants = await response.json();
        return participants;
    } catch (error) {
        console.error(error);
        throw new Error("Ishtirokchilarni yuklashda xatolik!");
    }
};

// Ovoz berish
export const vote = async (chatId, participantId) => {
    try {
        const response = await fetch(voteUrl, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({chatId, participantId}),
        });
        if (!response.ok) throw new Error("Ovoz berishda xatolik!");
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
        throw new Error("Ovoz berishda xatolik!");
    }
};
