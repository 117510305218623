// ParticipantList.js
import React, { useState, useEffect } from "react";
import { fetchParticipants } from "../api";
import VoteButton from "./VoteButton";

const ParticipantList = ({ chatId }) => {
    const [participants, setParticipants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const loadParticipants = async () => {
            try {
                const data = await fetchParticipants();
                setParticipants(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        loadParticipants();
    }, []);

    if (loading) {
        return <p>Yuklanmoqda...</p>;
    }

    if (error) {
        return <div className="alert error">{error}</div>;
    }

    return (
        <div className="container">
            <h1>Ovoz berish tizimi</h1>
            {error && <div className="alert error">{error}</div>}
            <h2>Ishtirokchilar ro'yxati</h2>
            <table>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Ishtirokchi</th>
                    <th>Ovozlar</th>
                    <th>Harakat</th>
                </tr>
                </thead>
                <tbody>
                {participants.map((participant, index) => (
                    <tr
                        key={participant.id}
                        className={index < 3 ? "top-3" : ""}
                    >
                        <td>{participant.order}</td>
                        <td>{participant.name}</td>
                        <td>{participant.pollCount}</td>
                        <td>
                            <div className="vote-button-container">
                                <VoteButton
                                    chatId={chatId}
                                    participantId={participant.id}
                                />
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ParticipantList;
