// VoteButton.js
import React, { useState } from "react";
import { vote } from "../api";

const VoteButton = ({ chatId, participantId }) => {
    const [hasVoted, setHasVoted] = useState(false);
    const [message, setMessage] = useState("");

    const handleVote = async () => {
        try {
            const result = await vote(chatId, participantId);
            if (result.status === "success") {
                setHasVoted(true);
                setMessage(result.message);
            } else {
                setMessage(result.message);
            }
        } catch (error) {
            setMessage("Ovoz berishda xatolik!");
        }
    };

    return (
        <button
            className={hasVoted ? "voted" : ""}
            onClick={handleVote}
            disabled={hasVoted}
        >
            {hasVoted ? "Siz allaqachon ovoz berdingiz" : "Ovoz berish"}
        </button>
    );
};

export default VoteButton;
